<template>
  <ion-fab
    v-if="whappyClientConfig.name == 'Yoube'"
    vertical="bottom"
    horizontal="end"
  >
    <ion-fab-button
      @click="$router.push('/shopping-cart')"
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon :icon="cart"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab
    v-if="whappyClientConfig.name == 'Trivellato'"
    vertical="bottom"
    horizontal="end"
  >
    <ion-fab-button
      @click="openModalNewKpiChat"
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <master-layout
    :showInfoPopHover="true"
    :showProfilePic="this.whappyClientConfig.showAvatarCampaigns"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
    smallTitle="le tue"
    bigTitle="Campagne"
    :showBack="false"
    titleStyle="vertical"
    :noPadding="true"
  >
    <div class="page">
      <!-- <div
        v-if="user"
        class="w-full justify-content-center align-items-center text-align-center my-4"
      >
        <span
          >Benvenuto<span v-if="user.nome" class="font-bold ml-2">{{
            user.nome
          }}</span
          >!</span
        ><br />
        <span>di seguito vedrai le tue campagne...</span>
      </div> -->

      <template v-if="loadingCampaigns">
        <p class="greeting-tag">Caricamento in corso...</p>
      </template>

      <template v-else-if="displayList !== null && displayList.length === 0">
        <p class="greeting-tag">
          {{ $t("campaigns.no_campaigns") }}
        </p>
      </template>

      <transition-group
        name="block-slide-in"
        :style="{ '--i': displayList.length }"
      >
        <template v-for="(listItem, i) in displayList">
          <trCard
            :key="i"
            :style="{ '--i': i }"
            :img="listItem.url_mission_cover && listItem.show_cover_in_menu"
            v-if="showList1 && showList2"
            @click="$router.push(getCampaignDefaultUrl(listItem))"
            cardType="secondary"
            sideWidth="lg"
            class="mb-4"
            :percCompletamento="true"
          >
            <template #perc-completamento>
              {{ Number(listItem.percentualeCompletamento).toFixed(0) }}%
            </template>
            <template #main>
              <div class="w-full flex flex-column py-2">
                <div class="flex flex-column campaign-name-and-details my-2">
                  <span class="text-14 font-bold">
                    {{ listItem.title }}
                  </span>
                  <span class="text-14">
                    {{ listItem.description }}
                  </span>
                </div>
                <div class="w-full h-full progressbar py-2 my-2">
                  <div class="campaign-datetime text-color-gray mb-2">
                    <span class="text-10">{{
                      new Date(listItem.data_inizio).toLocaleDateString("it", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    }}</span>
                    -
                    <span class="text-10">{{
                      new Date(listItem.data_fine).toLocaleDateString("it", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    }}</span>
                  </div>
                  <dark-common-progress-bar
                    :value="
                      (listItem.totalNumberOfDays -
                        listItem.remainingNumberOfDays) /
                      listItem.totalNumberOfDays
                    "
                  ></dark-common-progress-bar>
                  <span
                    v-if="listItem.remainingNumberOfDays > 0"
                    class="text-10"
                    >{{ listItem.remainingDaysString }}</span
                  >
                  <span class="text-10" v-else>La campagna è terminata</span>
                </div>
              </div>
            </template>
            <template #right-side>
              <div class="campaign-img">
                <img
                  v-if="
                    listItem.url_mission_cover && listItem.show_cover_in_menu
                  "
                  :src="listItem.url_mission_cover"
                  @error="handleImgError"
                />
              </div>
            </template>
          </trCard>
        </template>
      </transition-group>

      <!--  <template v-if="displayList">
        <div v-for="listItem in displayList" :key="listItem.id">
          

         <ion-card
            @click="$router.push(getCampaignDefaultUrl(listItem))"
            :class="{ open: listItem.isOpen, closed: !listItem.isOpen }"
            class="campaign-title costom-height"
          >
            <img
              v-if="listItem.url_mission_cover && listItem.show_cover_in_menu"
              :src="listItem.url_mission_cover"
              @error="handleImgError"
            />
            <ion-card-content>
              <ion-row>
                <ion-col>
                  <div class="status">
                    <ion-icon :icon="stopwatchOutline"></ion-icon>
                    <span v-if="listItem.isOpen">{{
                      listItem.remainingDaysString
                    }}</span>
                    <span v-if="listItem.isEnded">{{
                      $t("campaigns.ended")
                    }}</span>
                    <span v-if="listItem.isFuture">{{
                      $t("campaigns.not_yet_started")
                    }}</span>
                  </div>
                  <div v-if="!listItem.view_supervisor" class="percentage">
                    <span>{{ listItem.currentPercentage }}%</span>
                  </div>
                  <div v-else class="percentage">
                    <span><ion-icon :icon="eyeOutline"></ion-icon></span>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row class="ion-padding-top ion-margin-top ion-no-padding">
                <ion-col>
                  <div class="title-and-progress">
                    <div class="details">
                      <h3>{{ listItem.title }}</h3>
                      <span class="period">{{ listItem.startEnd }}</span>
                    </div>
                    <common-progress-bar
                      :value="
                        listItem.remainingNumberOfDays /
                        listItem.totalNumberOfDays
                      "
                    ></common-progress-bar>
                  </div>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card> 
        </div>
      </template>-->
    </div>
  </master-layout>
</template>

<script>
import ApiService from "../common/service.api";
import { dateToDayMonth, translate } from "../common/helper.auth";
import trCard from "@/components/Nuovi/trCard.vue";
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonicSafeString,
  IonItem,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";
import CampaignService from "../common/mixins/Campaign";
import UserService from "../common/mixins/User";
import { IonProgressBar } from "@ionic/vue";
import { mapActions } from "vuex";
import moment from "moment";
import {
  stopwatchOutline,
  eyeOutline,
  cart,
  add,
  cartOutline,
  list,
} from "ionicons/icons";
import DarkCommonProgressBar from "../components/DarkCommonProgressBar";
import ModalNewOrdine from "./Modals/ModalNewOrdine.vue";

export default {
  name: "Campaigns",
  components: {
    ModalNewOrdine,
    IonItem,
    IonCard,
    IonCardContent,
    IonIcon,
    IonProgressBar,
    DarkCommonProgressBar,
    IonRow,
    IonCol,
    trCard,
  },
  mixins: [CampaignService, UserService],
  created() {
    this.loadingCampaigns = true;
    console.log("get campaigns ... ");
    var data = this.getCampaigns({
      filterForHome: true,
      forceRefresh: true,
    })
      .then((data) => {
        this.displayList.splice(0);
        if (this.settings.skip_menu_campagne && data.length) {
          this.$router.push(this.getCampaignDefaultUrl(data[0]));
        }
        data.forEach((element) => {
          element.view_supervisor = this.isSupervisedCampaign(element);
          this.displayList.push(element);
          setTimeout(() => {
            this.$nextTick(() => {
              this.showList1 = true;
            });
          }, 1);
        });
        // this.displayList = data;
      })
      .finally(() => {
        this.loadingCampaigns = false;
      });
  },
  mounted() {
    //     this.displayList.forEach((element) => {
    //       element.view_supervisor = this.isSupervisedCampaign(element);
    //     });
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
  data() {
    return {
      loadingCampaigns: false,
      cart,
      add,
      cartOutline,
      stopwatchOutline,
      userAvatar: null,
      displayList: [],
      isSupervised: false,
      eyeOutline,
      showList1: false,
      showList2: false,
      INFOPOPOVER_MAP: {
        Yoube:
          "In questa sezione trovi le campagne extra promozionali a te dedicate. Piccole azioni ti faranno accumulare riconoscimenti e crediti. Potrai tenere monitorate le tue statistiche e cosa ti manca per ottenere riconoscimenti e premi.",
        default:
          "In questa sezione trovi le campagne extra promozionali a te dedicate. Piccole azioni ti faranno accumulare riconoscimenti e crediti. Potrai tenere monitorate le tue statistiche e cosa ti manca per ottenere riconoscimenti e premi.",
      },
      options: [],
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    async openModalNewKpiChat() {
      const modal = await modalController.create({
        component: ModalNewOrdine,
        cssClass: "common-modal",
      });

      modal.onDidDismiss().then(async () => {
        await ApiService.get(`SalesChat/GetOrders`).then((res) => {
          this.items = [];
          res.data.forEach((element) => {
            this.items.push(element);
          });
        });
      });
      return modal.present();
    },
  },
  //   created() {
  //   var data = ApiService.get("Campaign").then((res) => {
  //     this.displayList = res.data;
  //   });
  // },
  // mounted() {
  //   this.displayList.map((item) => {
  //     return {
  //       id: item.id,
  //       title: translate(item.title),
  //       description: translate(item.description),
  //     };
  //   });
  // },
};
</script>

<style lang="scss" scoped>
ion-card {
  --background: var(--ion-color-primary);
  &.closed {
    --background: var(--ion-color-primary-shade);
  }
  color: var(--text-color-light);

  ion-icon {
    font-size: 150%;
    margin: 0 5px -3px 0;
  }
}

.status {
  width: 70%;
  float: left;
  font-size: 70%;
}
.percentage {
  width: 30%;
  float: left;
  text-align: right;
  font-weight: bold;
}
.details {
  width: 100%;
  clear: both;
  h3 {
    font-size: 110%;
    color: var(--text-color-light);
    font-weight: bold;
    margin-bottom: 0px;
  }
  .period {
    font-size: 70%;
  }
}

.page {
  background: white;
  padding: 1rem;
  min-height: calc(100vh - 220px);
}

.campaign-img {
  height: 100%;
  max-height: 222px !important;
  img {
    height: 100%;
    object-fit: cover;
  }
}
</style>
